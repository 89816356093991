import styled from "styled-components"

export const GridContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 130px 130px 0;
  position: relative;

  .left {
    padding-top: 150px;
    opacity: 0;
    transform: translateY(100px);
  }
  .right {
    opacity: 0;
    transform: translateY(300px);
  }

  .works-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transform: translateY(200px);
  }

  @media (max-width: 599px) {
    flex-direction: column;
    padding: 20px 20px 0;
    .hp-works-grid-right {
      margin-top: 0;
    }
  }
  @media (min-width: 600px) {
    flex-direction: column;
    padding: 20px 20px 0;
    .hp-works-grid-right {
      margin-top: 0;
    }
  }
  @media (min-width: 900px) {
    flex-direction: row;
    padding: 130px 130px 0;
  }
`
