const DATA = [
  {
    id: 3,
    header: "Melloframes",
    titleLine1: "Branding, Storytelling &",
    titleLine2: "Digital Experiences.",
    tagLine: "web ux/ui design & development",
    image: "./works/home-mellowframes.jpg",
    imageName: "mellowframes",
    color: "#F9F1F4",
  },
  {
    id: 1,
    header: "SquireCircle",
    titleLine1: "Being a landlord made",
    titleLine2: "too easy",
    tagLine: "web & mobile design and developement",
    image: "./works/home-squirecircle.jpg",
    imageName: "cabapp",
    color: "#EFEDF5",
  },
  {
    id: 2,
    header: "Feteclub",
    titleLine1: "Manage money saved",
    titleLine2: "for events",
    tagLine: "webs & mobile app design and developement",
    image: "./works/home-feteclub.jpg",
    imageName: "farmer",
    color: "#EEF6F1",
  },
  {
    id: 4,
    header: "BigOnFuss",
    titleLine1: "Create a fuss about what you",
    titleLine2: "think is interesting",
    tagLine: "web & mobile app design and developement",
    image: "./works/home-bigonfuss.jpg",
    imageName: "kitchen",
    color: "#F6EEED",
  },
]

export default DATA;