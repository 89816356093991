import React from 'react';

import {
  Container, WorkShowcase, WorkImage,
  Roles,
  Title
} from '../styles/works-work';

const WorksWork: React.FC<any> = ({
  id,
  header,
  titleLine1,
  titleLine2,
  tagLine,
  image,
  imageName,
  color
}) => {
  return (
    <Container>
      <WorkShowcase>
        <WorkImage className="parallax-content" src={image} />
      </WorkShowcase>
      <Roles>{tagLine}</Roles>
      <Title>{header}</Title>
    </Container>
  )
};

export default WorksWork;
