import React, { useEffect, useRef } from "react"
import { PageProps } from "gatsby"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import firebase from "gatsby-plugin-firebase"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WorksWork from "../components/works-work"

import { GridContainer } from "../styles/works";
import { getGlobals, EVENTS } from "../helpers/globals";

import WorksBg from "../svgs/worksbg.svg";
import WorksData from "../data/works";

gsap.registerPlugin(ScrollTrigger);

const WorksPage: React.FC<PageProps<any>> = ({ data, path }) => {
  const gridLeftRef = useRef(null);
  const gridRightRef = useRef(null);
  const scroller = useRef(null);
  const momentumScrollContext = useRef(getGlobals('SMOOTH_SCROLL_CONTEXT'));

  const initScrollAnims = () => {
    gsap.utils.toArray(".parallax-content").forEach((section: any, i) => {
      const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;
      gsap.fromTo(section, {
        y: -heightDiff
      }, {
        scrollTrigger: {
          trigger: section.parentElement,
          scrub: true
        },
        y: 0,
        ease: "none"
      });
    });
  };

  const setUpScrollProxy = () => {
    scroller.current = document.querySelector(`#abh-momentum-scroll`);
    ScrollTrigger.scrollerProxy(scroller.current, {
      scrollTop (value) {
        if (arguments.length) {
          if (momentumScrollContext.current) {
            momentumScrollContext.current.scrollTop = value;
          }
        }
        return momentumScrollContext.current?.scrollTop;
      }
    });
    if (momentumScrollContext.current) {
      momentumScrollContext.current?.addListener(ScrollTrigger.update);
    }
    ScrollTrigger.defaults({ scroller: scroller.current });
    initScrollAnims();
  }

  useEffect(() => {
    window.addEventListener(EVENTS.onSmoothScrollInit, () => {
      momentumScrollContext.current = getGlobals('SMOOTH_SCROLL_CONTEXT');
      setUpScrollProxy();
    });

    gsap.to(gridLeftRef.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      delay: 0.75,
      ease: "power3.easeIn"
    });
    gsap.to(gridRightRef.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      delay: 1,
      ease: "power3.easeIn"
    });
    gsap.to(".works-bg", {
      y: 0,
      opacity: 1,
      duration: 1,
      delay: 0.5,
      ease: "power3.easeIn"
    });
  }, []);

  React.useEffect(() => {
    if (firebase) {
      firebase.analytics().logEvent('visited_works')
    }
  }, [firebase]);

  const left = [];
  const right = [];
  WorksData.forEach((i, index) => {
    if (index % 2 === 0) {
      left.push(i);
    } else {
      right.push(i);
    }
  });

  return (
    <Layout>
      <SEO title="Our Works" />
      <GridContainer>
        <WorksBg className="works-bg" />
        <div className="left" ref={gridLeftRef}>
          {
            left.map(i => <WorksWork {...i} />)
          }
        </div>
        <div className="right" ref={gridRightRef}>
          {
            right.map(i => <WorksWork {...i} />)
          }
        </div>
      </GridContainer>
    </Layout>
  );
}

export default WorksPage
