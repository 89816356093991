import styled from "styled-components"

export const Container = styled.div`
  margin-bottom: 75px;
`

export const WorkShowcase = styled.div`
  width: 350px;
  height: 460px;
  margin-bottom: 30px;
  overflow: hidden;

  @media (max-width: 599px) {
    width: 100%;
    height: 70vh;
  }
  @media (min-width: 600px) {
    width: 275px;
    height: 430px;
  }
  @media (min-width: 900px) {
    width: 350px;
    height: 460px;
  }
`

export const Roles = styled.h6`
  font-size: 1rem;
  font-weight: 300;
  color: #868686;
  margin-bottom: 10px;
`

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
`

export const WorkImage = styled.img`
  height: 120%;
  width: 100%;
  object-fit: cover;
`
